@use '@angular/material' as mat;
@use 'sass:map';
@import './vars';

.root {
  /* toolbar */
  .mat-toolbar {
    font-size: 13px;
    min-height: 40px;

    .mat-toolbar-row,
    &.mat-toolbar-single-row {
      height: 40px;
    }
  }

  /* menu */
  .mat-menu-panel {
    min-height: 35px;

    .mat-menu-content {
      padding-top: 5px;
      padding-bottom: 5px;
    }

    .mat-menu-item {
      line-height: 26px;
      height: 26px;

      // `button-theme-property` is private now
      $primary: map.get($crm-theme, primary);
      $accent: map.get($crm-theme, accent);
      $warn: map.get($crm-theme, warn);
      $foreground: map.get($crm-theme, foreground);

      &.mat-primary {
        color: mat.get-color-from-palette($primary, text);
      }
      &.mat-accent {
        color: mat.get-color-from-palette($accent, text);
      }
      &.mat-warn {
        color: mat.get-color-from-palette($warn, text);
      }

      &.mat-primary,
      &.mat-accent,
      &.mat-warn,
      &.mat-button-disabled {
        &.mat-button-disabled {
          color: mat.get-color-from-palette($foreground, disabled-button);
        }
      }
    }
  }

  /* side nav */
  .mat-nav-list[dense] {
    .mat-list-item.menu-item {
      height: 40px;

      .mat-list-item-content {
        height: 40px;
        padding-left: 8px;
        padding-right: 8px;
      }

      .mat-list-text {
        overflow: initial;
        padding-left: 10px;
        padding-right: 10px;

        .mat-line {
          overflow: initial;
        }
      }
    }

    .mat-list-item.mat-list-item-with-avatar .mat-list-item-content {
      &,
      .mat-list-text {
        padding-left: 8px;
      }
    }
  }

  /* tabs */
  .mat-tab-group.mat-background-card {
    .mat-tab-header {
      background-color: mat.get-color-from-palette($crm-background, card);
    }
  }

  .mat-tab-header {
    .mat-tab-label,
    .mat-tab-link {
      height: 37px;
      min-width: 100px;
      padding-left: 15px;
      padding-right: 15px;
    }
  }

  /* buttons */
  $buttonHeight: 32px;

  .mat-button:not(.mat-icon-button),
  .mat-stroked-button,
  .mat-raised-button,
  .mat-flat-button {
    padding-left: 12px;
    padding-right: 12px;
    line-height: $buttonHeight;
    font-weight: 400;
  }

  .mat-button.mat-trivial,
  .mat-flat-button.mat-trivial,
  .mat-stroked-button.mat-trivial {
    color: mat.get-color-from-palette($crm-foreground, secondary-text);
  }

  .mat-mini-fab,
  .mat-icon-button:not(.mat-calendar-previous-button):not(.mat-calendar-next-button) {
    width: $buttonHeight;
    height: $buttonHeight;
    line-height: $buttonHeight;

    .mat-button-wrapper {
      padding-top: 0;
      padding-bottom: 0;
    }

    .mat-icon {
      @include mat-icon-size(13px);

      &.-header-icon {
        @include mat-icon-size(20px);
      }
    }
  }

  .mat-form-field-suffix .mat-datepicker-toggle,
  .mat-form-field-suffix {
    .mat-icon-button {
      width: 24px !important;
      height: 24px !important;
      line-height: 24px !important;
    }
  }

  /* button toggle */
  .mat-button-toggle .mat-button-toggle-label-content {
    line-height: 25px;
  }

  /* icons */
  .mat-icon {
    @include mat-icon-size(16px);

    &.mat-trivial {
      color: mat.get-color-from-palette($crm-foreground, secondary-text);
    }
  }

  /* form controls */
  .mat-form-field {
    font-size: 12px;
  }

  .mat-select-panel,
  .mat-autocomplete-panel {
    .mat-optgroup-label,
    .mat-option {
      height: 2.5em;
      font-size: 12px;
      line-height: 2.5em;
    }
  }

  .mat-checkbox-label {
    font-size: 12px;
  }

  .mat-checkbox-inner-container {
    width: 16px;
    height: 16px;
  }

  .mat-form-field-prefix .mat-icon {
    margin-right: 5px;
    color: mat.get-color-from-palette($crm-foreground, icon);
  }

  /* table */
  .mat-header-row,
  .mat-row {
    padding: 3px 12px;
  }

  .mat-row {
    min-height: 34px;
  }

  .mat-header-row {
    min-height: 39px;
  }

  .mat-table {
    td.mat-cell:first-of-type,
    td.mat-footer-cell:first-of-type,
    th.mat-header-cell:first-of-type,
    .mat-cell:first-of-type,
    mat-footer-cell:first-of-type,
    mat-header-cell:first-of-type {
      padding-left: 12px;
    }

    td.mat-cell:last-of-type,
    td.mat-footer-cell:last-of-type,
    th.mat-header-cell:last-of-type,
    mat-cell:last-of-type,
    mat-footer-cell:last-of-type,
    mat-header-cell:last-of-type {
      padding-right: 12px;
    }
  }

  /* expansion panel*/
  .mat-expansion-panel-body {
    padding: 0 12px 15px;
  }

  /* badge */
  .mat-badge {
    &.mat-badge-small .mat-badge-content {
      font-size: 9px;
    }

    &.mat-badge-trivial {
      .mat-badge-content {
        background: mat.get-color-from-palette($crm-background, disabled-button);
        color: mat.get-color-from-palette($crm-foreground, disabled-text);
      }
    }
  }

  /* chips */
  .mat-chip-list-wrapper {
    .mat-standard-chip {
      margin: 2px;
      border-radius: 15px;
      min-height: 20px;

      &.mat-chip-with-trailing-icon {
        padding: 1px 3px 1px 6px;
      }
    }

    .mat-chip {
      font-size: 12px;
      font-weight: 400;
      line-height: 1;
      background-color: mat.get-color-from-palette($crm-background, hover);

      .mat-chip-remove.mat-icon {
        width: 15px;
        height: 15px;
        line-height: 15px;
        font-size: 15px;
      }
    }
  }

  /* radio button */
  .mat-radio-button {
    margin-bottom: 2px;
  }

  .mat-radio-label {
    font-size: 12px;
  }

  .mat-radio-container,
  .mat-radio-outer-circle,
  .mat-radio-inner-circle {
    width: 16px;
    height: 16px;
  }

  /* form field */
  .mat-form-field-appearance-legacy.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
  .mat-form-field-appearance-legacy.mat-form-field-can-float
    .mat-input-server:focus
    + .mat-form-field-label-wrapper
    .mat-form-field-label {
    transform: translateY(-1.28125em) scale(0.92) perspective(100px) translateZ(0.001px);
  }

  /* pagination */
  .crm-paginator-no-total .mat-paginator-range-label {
    display: none;
  }

  /* dialog */
  .mat-dialog-actions .mat-button + .mat-flat-button {
    margin-left: 8px;
  }
  .mat-dialog-content {
    font-size: 12px;
  }

  /* stepper */
  .mat-stepper-label-position-bottom .mat-horizontal-stepper-header {
    padding: 0 20px 15px;
  }

  .mat-horizontal-content-container {
    padding: 0;
  }

  .mat-step-header.cdk-program-focused {
    background-color: unset;
  }

  .mat-step-label {
    font-size: 11px;
  }

  .mat-step-label-selected {
    font-size: 12px;
  }

  .mat-stepper-label-position-bottom .mat-horizontal-stepper-header .mat-step-label {
    padding-top: 10px;
  }

  .mat-step-icon {
    width: 20px;
    height: 20px;
  }

  .mat-stepper-label-position-bottom .mat-horizontal-stepper-header:not(:first-child)::before,
  .mat-stepper-label-position-bottom .mat-horizontal-stepper-header:not(:last-child)::after,
  .mat-stepper-label-position-bottom .mat-stepper-horizontal-line {
    top: 10px;
  }

  .mat-step-icon-content {
    font-size: 11px;
  }

  .mat-tree-node {
    min-height: 32px;
    overflow: clip;
  }

  .mat-select-panel mat-option.mat-option,
  .mat-autocomplete-panel .mat-option {
    height: unset;
    padding: 6px 16px;
  }

  .mat-option-text {
    white-space: normal;
    line-height: 18px;
  }
}
