/**
* @link https://github.com/rsms/raster
* @link https://github.com/johannschopplich/raster-sass
 */
$raster-element: crm-grid !default;
$grid-attribute: columns !default;
$cell-attribute: span !default;
$columns: 12 !default;
$default-cols: 12 !default;

$include-debug: true !default;

@mixin make-cells($suffix: null) {
  $infix: if($suffix == null, '', '-#{$suffix}');

  @for $i from 1 through $columns {
    &[#{$grid-attribute}#{$infix}='#{$i}'] {
      --grid-tc: repeat(#{$i}, 1fr);
    }
  }

  > {
    // span=start...
    @for $i from 1 through $columns {
      [#{$cell-attribute}#{$infix}^='#{$i}'] {
        --grid-cs: #{$i};
      }
    }

    // span=...+width, span=...-end
    [#{$cell-attribute}#{$infix}$='+1'],
    [#{$cell-attribute}='1'] {
      --grid-ce: 1;
    }

    @for $i from 1 through $columns {
      [#{$cell-attribute}#{$infix}$='+#{$i}'],
      [#{$cell-attribute}#{$infix}$='-#{$i - 1}'],
      [#{$cell-attribute}#{$infix}='#{$i}'] {
        --grid-ce: #{$i};
      }
    }

    [#{$cell-attribute}#{$infix}$='-#{$columns}'] {
      --grid-ce: #{$columns + 1};
    }

    // Connect vars
    [#{$cell-attribute}#{$infix}] {
      grid-column-end: span var(--grid-ce);
    }

    [#{$cell-attribute}#{$infix}*='+'],
    [#{$cell-attribute}#{$infix}*='-'],
    [#{$cell-attribute}#{$infix}*='..'] {
      grid-column-start: var(--grid-cs);
    }

    [#{$cell-attribute}#{$infix}*='-'],
    [#{$cell-attribute}#{$infix}*='..'] {
      grid-column-end: var(--grid-ce);
    }

    [#{$cell-attribute}#{$infix}='row'] {
      grid-column: 1 / -1;
    }
  }
}

#{$raster-element} {
  --grid-tc: repeat(#{$default-cols}, 1fr); // Template columns
  --grid-cs: 1; // Column start
  --grid-ce: -1; // Column end
  display: grid;
  grid-template-columns: var(--grid-tc);

  // Cell or column
  > * {
    -webkit-appearance: none;
    appearance: none;
    display: block;
  }

  @include make-cells();
}
