// moved to project.json styles section
//@import 'ng-zorro-antd/pagination/style/index.min.css';

// can't import base styles from ng-ant, because global styles break application

.ant-select-item-option,
.ant-pagination.mini .ant-pagination-item,
.ant-select-single .ant-select-selector {
  box-sizing: border-box;
}
