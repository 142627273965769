@use '@angular/material' as mat;
@import '../styles/theme';

// Spacing
$spacer: 4px;
$spacers: (
  0: 0,
  1: $spacer,
  2: $spacer * 2,
  3: $spacer * 3,
  4: $spacer * 4,
  5: $spacer * 5,
  6: $spacer * 6,
  7: $spacer * 8,
  8: $spacer * 10,
);

@mixin mat-icon-size($size: 24px) {
  height: $size !important;
  width: $size !important;
  font-size: $size !important;
  line-height: $size !important;
}

@mixin scrollbar($scrollbar-width: 4px) {
  &::-webkit-scrollbar {
    width: $scrollbar-width;
    height: $scrollbar-width;
  }

  &::-webkit-scrollbar-track {
    border-radius: $scrollbar-width + 2;
    background: mat.get-color-from-palette($crm-background, background);
  }

  &::-webkit-scrollbar-thumb {
    border-radius: $scrollbar-width + 2;
    background-color: mat.get-color-from-palette($crm-background, status-bar);
  }
}

@mixin headings() {
  h1 {
    margin-top: 0;
    margin-bottom: mat.font-size($crm-typography, headline);
    line-height: 1.5;
    font-size: mat.font-size($crm-typography, headline);
    font-weight: mat.font-weight($crm-typography, headline);
  }

  h2 {
    margin-top: 0;
    margin-bottom: mat.font-size($crm-typography, title);
    line-height: 1.5;
    font-size: mat.font-size($crm-typography, title);
    font-weight: mat.font-weight($crm-typography, title);
  }

  h3 {
    margin-top: 0;
    margin-bottom: mat.font-size($crm-typography, subheading-2);
    line-height: 1.5;
    font-size: mat.font-size($crm-typography, title);
    font-weight: mat.font-weight($crm-typography, subheading-2);
  }
}

$animation-speed-fast: 0.2s;
$animation-speed-quick: 0.4s;
$animation-speed-brisk: 0.6s;
$animation-speed-medium: 0.8s;
$animation-speed-slow: 1s;

$layout-max-width: 1600px;
