@import './vars';
@import './utils';
@import './default-theme';

@function dsCreateTheme($theme) {
  @return dsMergeThemes($dsDefaultTheme, $theme);
}

@mixin dsInitTheme($theme: $dsDefaultTheme) {
  $cssVarsList: dsCreateCssVarsList($theme, $dsCssVarPrefix);

  @each $cssVar, $value in $cssVarsList {
    #{$cssVar}: $value;
  }
}

@mixin dsInitDefaultTheme($theme) {
  $cssVarsList: dsCreateCssVarsList($theme, $dsCssVarPrefix, $dsCssVarDefaultPostfix);

  @each $cssVar, $value in $cssVarsList {
    #{$cssVar}: $value;
  }
}

@mixin dsSetCssVar($cssVarPath, $value) {
  #{dsGetCssVarName($cssVarPath...)}: $value;
}

@mixin dsSetDefaultCssVar($cssVarPath, $value) {
  #{dsGetCssVarName($cssVarPath...)}-#{$dsCssVarDefaultPostfix}: $value;
}

@mixin dsSetCssVarNew($cssVarPath, $value) {
  #{dsGetCssVarNameNew($cssVarPath...)}: $value;
}
