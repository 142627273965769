@import './vars';

@function createCssVarsList($theme, $cssVarPart) {
  $result: ();

  @each $field, $value in $theme {
    $cssVar: $cssVarPart + '-' + $field;

    @if type-of($value) == 'map' {
      $result: map-merge($result, createCssVarsList($value, $cssVar));
    } @else if ($value != null) {
      $result: map-merge($result, (#{$cssVar}: $value));
    }
  }

  @return $result;
}

@mixin createThemeVars($theme, $themeField: null) {
  @if $themeField {
    $theme: map-get($theme, $themeField);
  }

  $cssVarsList: createCssVarsList($theme, $uiKitCssVarPrefix);

  @each $cssVar, $value in $cssVarsList {
    #{$cssVar}: $value;
  }
}

@function getUiKitVar($path...) {
  $cssVar: getUiKitCssVar($path...);

  @return var(#{$cssVar});
}

@function getUiKitRgbVar($path...) {
  $path: set-nth($path, -1, #{nth($path, -1)}Rgb);
  @return getUiKitVar($path...);
}

@mixin setUiKitVar($cssVarPath, $value) {
  #{getUiKitCssVar($cssVarPath...)}: $value;
}

@function getUiKitCssVar($path...) {
  $cssVar: $uiKitCssVarPrefix;

  @each $part in $path {
    $cssVar: $cssVar + '-' + $part;
  }

  @return $cssVar;
}

@function recursiveMapMerge($map1, $map2, $config: ()) {
  @if (type-of($map1) == map or (type-of($map1) == list and length($map1) == 0)) and
    (type-of($map2) == map or (type-of($map2) == list and length($map2) == 0))
  {
    $result: $map1;
    @each $key, $value in $map2 {
      @if (type-of(map-get($result, $key)) == map and type-of($value) == map) {
        $result: map-merge(
          $result,
          (
            $key: recursiveMapMerge(map-get($result, $key), $value),
          )
        );
      } @else if
        (map-get($config, 'merge-lists') and type-of(map-get($result, $key)) == list and type-of($value) == list)
      {
        $result: map-merge(
          $result,
          (
            $key: join(map-get($result, $key), $value),
          )
        );
      } @else {
        $result: map-merge(
          $result,
          (
            $key: $value,
          )
        );
      }
    }
    @return $result;
  } @else {
    @warn "recursiveMapMerge() expects it\'s parameters to be map types!";
    @return null;
  }
}
