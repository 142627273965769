@import './vars';

/**
@deprecated
 */
@function getPalletColor($path...) {
  @warn 'getPalletColor is deprecated';
  @return mapDeepGet($paletteColors, $path...);
}

@function getTintedColor($color) {
  @return map-get($tintedColors, $color);
}

@function mapDeepGet($map, $keys...) {
  @each $key in $keys {
    @if map-has-key($map, $key) {
      $map: map-get($map, $key);
    } @else {
      @error 'Key: #{$key} does not exist in #{$map}';
    }
  }
  @return $map;
}
