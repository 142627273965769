@use '@angular/material' as mat;
// @link https://material.io/icons/ – material icons
@import './styles/theme';

// TODO(v15): As of v15 mat.legacy-core no longer includes default typography styles.
//  The following line adds:
//    1. Default typography styles for all components
//    2. Styles for typography hierarchy classes (e.g. .mat-headline-1)
//  If you specify typography styles for the components you use elsewhere, you should delete this line.
//  If you don't need the default component typographies but still want the hierarchy styles,
//  you can delete this line and instead use:
//    `@include mat.legacy-typography-hierarchy($crm-typography);`
@include mat.all-legacy-component-typographies($crm-typography);
@include mat.legacy-core();
@include mat.legacy-input-typography($crm-typography);
@include mat.all-legacy-component-themes($crm-theme);

@import './styles/material-customization';
@import '@danielmoncada/angular-datetime-picker/assets/style/picker.min.css';
@import 'angular-calendar/css/angular-calendar.css';
@import './styles/ng-zorro-ant-customization';
@import './styles/vars';
@import './styles/grid';
@import './libs/shared/ui-kit/vars';
@import './libs/shared/ui-kit/global';
@import './libs/shared/ui-kit/theming';

@import 'libs/shared/ui-kit-v2/theme/main';
@import 'libs/shared/ui-kit-v2/theme/themes';
@import 'libs/shared/ui-kit-v2/tooltip/main';

:root {
  @include dsInitTheme();
  @include dsInitTheme($skyengTheme);
}

body {
  // @include dsInitTheme();
}

body {
  font-size: mat.font-size($crm-typography, body-2);
  background-color: mat.get-color-from-palette($crm-background, background);
  font-family: Roboto, Helvetica Neue Light, Helvetica Neue, Helvetica, Arial, Lucida Grande, sans-serif;
  margin: 0;
}

body,
html {
  padding: 0;
  width: 100%;
  height: 100%;
  min-height: 100%;
}

.mat-drawer-container {
  background-color: mat.get-color-from-palette($crm-background, background);
}

::selection {
  background-color: mat.get-color-from-palette($crm-primary, 100);
}

@keyframes crm-root-spinner {
  to {
    transform: rotate(360deg);
  }
}

crm-root:empty::after {
  content: '';
  box-sizing: border-box;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 70px;
  height: 70px;
  margin-top: -70px;
  margin-left: -70px;
  border-radius: 50%;
  border: 7px solid transparent;
  border-top-color: mat.get-color-from-palette($crm-primary);
  border-right-color: #2196f3;
  animation: crm-root-spinner 1.2s linear infinite;
}

a {
  &,
  &:active,
  &:hover,
  &:visited {
    text-decoration: none;
  }
}

.crm-layout-width {
  max-width: $layout-max-width;
  margin-left: auto;
  margin-right: auto;
}

.root {
  @include headings();

  .crm-link {
    color: inherit;
    transition: color $animation-speed-fast linear;

    &:hover {
      color: mat.get-color-from-palette($crm-primary-palette, 600);
    }

    &.-underline {
      text-decoration: underline;
    }

    &.-primary-colored {
      color: mat.get-color-from-palette($crm-primary-palette, 600);

      &:hover {
        color: mat.get-color-from-palette($crm-primary-palette, 500);
        text-decoration: underline;
      }
    }
  }

  .crm-spacer {
    flex: 1 1 auto;
  }

  /* text alignment */
  @each $align in (left, center, right) {
    .crm-text-#{$align} {
      text-align: $align;
    }
  }

  .crm-table {
    width: 100%;
    border-collapse: collapse;

    tr {
      border-bottom: 1px solid mat.get-color-from-palette($crm-foreground, divider);
    }

    th,
    td {
      padding: 5px;
      font-size: 12px;
      line-height: 1.64;
    }

    th {
      font-weight: 500;
      color: mat.get-color-from-palette($crm-foreground, text);
    }

    td {
      color: mat.get-color-from-palette($crm-foreground, secondary-text);
    }

    &.-header-left {
      th {
        text-align: left;
      }
    }
  }

  .crm-snack-bar {
    &.-error {
      color: mat.get-color-from-palette($crm-warn, default-contrast);
      background-color: mat.get-color-from-palette($crm-warn, 600);
    }

    &.-warn {
      color: mat.get-color-from-palette($crm-warn, default-contrast);
      background-color: mat.get-color-from-palette($crm-accent-palette, 400);
    }
  }

  .crm-vertical-center {
    display: flex;
    align-items: center;
  }

  .crm-vertical-align-center {
    align-items: center;
  }

  // spacing: margin, padding
  @each $space, $size in $spacers {
    .m-#{$space} {
      margin: $size !important;
    }

    .mt-#{$space} {
      margin-top: $size !important;
    }

    .mr-#{$space} {
      margin-right: $size !important;
    }

    .mb-#{$space} {
      margin-bottom: $size !important;
    }

    .ml-#{$space} {
      margin-left: $size !important;
    }

    .mx-#{$space} {
      margin-left: $size !important;
      margin-right: $size !important;
    }

    .my-#{$space} {
      margin-top: $size !important;
      margin-bottom: $size !important;
    }

    .p-#{$space} {
      padding: $size !important;
    }

    .pt-#{$space} {
      padding-top: $size !important;
    }

    .pr-#{$space} {
      padding-right: $size !important;
    }

    .pb-#{$space} {
      padding-bottom: $size !important;
    }

    .pl-#{$space} {
      padding-left: $size !important;
    }

    .px-#{$space} {
      padding-left: $size !important;
      padding-right: $size !important;
    }

    .py-#{$space} {
      padding-top: $size !important;
      padding-bottom: $size !important;
    }
  }
}

.button-loading-directive {
  &:before {
    content: '';
    box-sizing: border-box;
    position: absolute;
    top: 50%;
    left: 50%;
    width: 20px;
    height: 20px;
    margin-top: -10px;
    margin-left: -10px;
    border-radius: 50%;
    border: 2px solid transparent;
    border-top-color: mat.get-color-from-palette($crm-primary);
    border-right-color: mat.get-color-from-palette($crm-primary);
    animation: button-loading-directive-spinner 1s linear infinite;
  }

  &.mat-primary:before {
    border-top-color: mat.get-color-from-palette($crm-primary);
    border-right-color: mat.get-color-from-palette($crm-primary);
  }

  &.mat-accent:before {
    border-top-color: mat.get-color-from-palette($crm-accent);
    border-right-color: mat.get-color-from-palette($crm-accent);
  }

  &.mat-warn:before {
    border-top-color: mat.get-color-from-palette($crm-warn);
    border-right-color: mat.get-color-from-palette($crm-warn);
  }
}

@keyframes button-loading-directive-spinner {
  to {
    transform: rotate(360deg);
  }
}

.w-100p {
  width: 100%;
}

:root {
  @include uiKitThemeCssVars($uiKitDefaultTheme);
}

.fcYXHM {
  right: unset !important;
  left: 20px !important;
} // temporary fix to prevent usedesk widget from overlapping pageSize selector in pil
