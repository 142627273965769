/**
 * Do not edit directly
 * Generated on Tue, 28 Nov 2023 09:23:36 GMT
 */

$color-text-icons-primary: #04121b !default;
$color-text-icons-secondary: rgba(4, 18, 27, 0.64) !default;
$color-text-icons-tertiary: rgba(4, 18, 27, 0.48) !default;
$color-text-icons-placeholder: rgba(4, 18, 27, 0.36) !default;
$color-text-icons-disabled: rgba(4, 18, 27, 0.24) !default;
$color-text-icons-inverted: #ffffff !default;
$color-text-icons-brand: #00a6f0 !default;
$color-text-icons-positive: #008a0b !default;
$color-text-icons-negative: #e12e3c !default;
$color-text-icons-warning: #a05900 !default;
$color-background-primary: #ffffff !default;
$color-background-secondary: #f4f5f6 !default;
$color-background-tertiary: #e9eced !default;
$color-background-neutral: #cfd7d8 !default;
$color-background-transparent-light: rgba(255, 255, 255, 0.28) !default;
$color-background-inverted: #2e2e2e !default;
$color-background-transparent-dark: rgba(4, 18, 27, 0.04) !default;
$color-background-brand: #00a6f0 !default;
$color-background-base-light: #000000 !default;
$color-background-base-dark: #ffffff !default;
$color-stroke-primary: #edeced !default;
$color-stroke-secondary: #f4f5f6 !default;
$color-state-positive-primary: #dbffde !default;
$color-state-positive-secondary: #b9fdbf !default;
$color-state-positive-tertiary: #14b331 !default;
$color-state-negative-primary: #ffebed !default;
$color-state-negative-secondary: #ffd6da !default;
$color-state-negative-tertiary: #f65a67 !default;
$color-state-warning-primary: #fff2e0 !default;
$color-state-warning-secondary: #ffe4c2 !default;
$color-state-warning-tertiary: #ec7813 !default;
$color-state-neutral-primary: #f4f5f6 !default;
$color-state-neutral-secondary: #e9eced !default;
$color-state-neutral-tertiary: #cfd7d8 !default;
$color-theme-blue-background-primary: #e4fbff !default;
$color-theme-blue-background-secondary: #c2f5ff !default;
$color-theme-blue-background-tertiary: #00a0eb !default;
$color-theme-blue-text-primary: #091e2a !default;
$color-theme-blue-text-secondary: rgba(9, 30, 42, 0.64) !default;
$color-theme-blue-text-tertiary: rgba(9, 30, 42, 0.48) !default;
$color-theme-blue-text-disabled: rgba(9, 30, 42, 0.24) !default;
$color-theme-blue-text-link: #1797cc !default;
$color-theme-blue-text-colored: #0070ab !default;
$color-theme-blue-icon-primary: #00aefa !default;
$color-theme-blue-icon-secondary: #19a3dc !default;
$color-theme-yellow-background-primary: #fff7c7 !default;
$color-theme-yellow-background-secondary: #fff08f !default;
$color-theme-yellow-background-tertiary: #f9d706 !default;
$color-theme-yellow-text-primary: #271907 !default;
$color-theme-yellow-text-secondary: rgba(39, 25, 7, 0.64) !default;
$color-theme-yellow-text-tertiary: rgba(39, 25, 7, 0.48) !default;
$color-theme-yellow-text-disabled: rgba(39, 25, 7, 0.24) !default;
$color-theme-yellow-text-link: #c08114 !default;
$color-theme-yellow-text-colored: #926100 !default;
$color-theme-yellow-icon-primary: #e0a500 !default;
$color-theme-yellow-icon-secondary: #cf8c16 !default;
$color-theme-orange-background-primary: #fff2e0 !default;
$color-theme-orange-background-secondary: #ffe4c2 !default;
$color-theme-orange-background-tertiary: #ec7813 !default;
$color-theme-orange-text-primary: #2b1707 !default;
$color-theme-orange-text-secondary: rgba(43, 23, 7, 0.64) !default;
$color-theme-orange-text-tertiary: rgba(43, 23, 7, 0.48) !default;
$color-theme-orange-text-disabled: rgba(43, 23, 7, 0.24) !default;
$color-theme-orange-text-link: #d27714 !default;
$color-theme-orange-text-colored: #a05900 !default;
$color-theme-orange-icon-primary: #fb8023 !default;
$color-theme-orange-icon-secondary: #e38117 !default;
$color-theme-salad-background-primary: #edfebd !default;
$color-theme-salad-background-secondary: #dbf88b !default;
$color-theme-salad-background-tertiary: #abeb00 !default;
$color-theme-salad-text-primary: #111f06 !default;
$color-theme-salad-text-secondary: rgba(17, 31, 6, 0.64) !default;
$color-theme-salad-text-tertiary: rgba(17, 31, 6, 0.48) !default;
$color-theme-salad-text-disabled: rgba(17, 31, 6, 0.24) !default;
$color-theme-salad-text-link: #5f9e14 !default;
$color-theme-salad-text-colored: #457700 !default;
$color-theme-salad-icon-primary: #74b700 !default;
$color-theme-salad-icon-secondary: #67ab17 !default;
$color-theme-mint-background-primary: #dbffe9 !default;
$color-theme-mint-background-secondary: #b4fdd1 !default;
$color-theme-mint-background-tertiary: #55f194 !default;
$color-theme-mint-text-primary: #07200e !default;
$color-theme-mint-text-secondary: rgba(7, 32, 14, 0.64) !default;
$color-theme-mint-text-tertiary: rgba(7, 32, 14, 0.48) !default;
$color-theme-mint-text-disabled: rgba(7, 32, 14, 0.24) !default;
$color-theme-mint-text-link: #14a34c !default;
$color-theme-mint-text-colored: #007b35 !default;
$color-theme-mint-icon-primary: #0abd5d !default;
$color-theme-mint-icon-secondary: #17b153 !default;
$color-theme-red-background-primary: #ffebeb !default;
$color-theme-red-background-secondary: #ffd7d6 !default;
$color-theme-red-background-tertiary: #f65c5a !default;
$color-theme-red-text-primary: #2e0505 !default;
$color-theme-red-text-secondary: rgba(46, 5, 5, 0.64) !default;
$color-theme-red-text-tertiary: rgba(46, 5, 5, 0.48) !default;
$color-theme-red-text-disabled: rgba(46, 5, 5, 0.24) !default;
$color-theme-red-text-link: #ed5c5d !default;
$color-theme-red-text-colored: #d01e20 !default;
$color-theme-red-icon-primary: #ff7775 !default;
$color-theme-red-icon-secondary: #ed5c5d !default;
$color-theme-pink-background-primary: #ffebf5 !default;
$color-theme-pink-background-secondary: #ffd6ec !default;
$color-theme-pink-background-tertiary: #f155ad !default;
$color-theme-pink-text-primary: #2d061f !default;
$color-theme-pink-text-secondary: rgba(45, 6, 31, 0.64) !default;
$color-theme-pink-text-tertiary: rgba(45, 6, 31, 0.48) !default;
$color-theme-pink-text-disabled: rgba(45, 6, 31, 0.24) !default;
$color-theme-pink-text-link: #eb4cb7 !default;
$color-theme-pink-text-colored: #c1238e !default;
$color-theme-pink-icon-primary: #ff6bbf !default;
$color-theme-pink-icon-secondary: #eb4cb7 !default;
$color-theme-violet-background-primary: #f9ebff !default;
$color-theme-violet-background-secondary: #f4d6ff !default;
$color-theme-violet-background-tertiary: #d05af6 !default;
$color-theme-violet-text-primary: #23082b !default;
$color-theme-violet-text-secondary: rgba(35, 8, 43, 0.64) !default;
$color-theme-violet-text-tertiary: rgba(35, 8, 43, 0.48) !default;
$color-theme-violet-text-disabled: rgba(35, 8, 43, 0.24) !default;
$color-theme-violet-text-link: #cd5cee !default;
$color-theme-violet-text-colored: #a339c0 !default;
$color-theme-violet-icon-primary: #de7aff !default;
$color-theme-violet-icon-secondary: #cd5cee !default;

$dsColors: (
  'color': (
    'text-icons': (
      'primary': $color-text-icons-primary,
      'secondary': $color-text-icons-secondary,
      'tertiary': $color-text-icons-tertiary,
      'placeholder': $color-text-icons-placeholder,
      'disabled': $color-text-icons-disabled,
      'inverted': $color-text-icons-inverted,
      'brand': $color-text-icons-brand,
      'positive': $color-text-icons-positive,
      'negative': $color-text-icons-negative,
      'warning': $color-text-icons-warning,
    ),
    'background': (
      'primary': $color-background-primary,
      'secondary': $color-background-secondary,
      'tertiary': $color-background-tertiary,
      'neutral': $color-background-neutral,
      'transparent-light': $color-background-transparent-light,
      'inverted': $color-background-inverted,
      'transparent-dark': $color-background-transparent-dark,
      'brand': $color-background-brand,
      'base-light': $color-background-base-light,
      'base-dark': $color-background-base-dark,
    ),
    'stroke': (
      'primary': $color-stroke-primary,
      'secondary': $color-stroke-secondary,
    ),
    'state': (
      'positive': (
        'primary': $color-state-positive-primary,
        'secondary': $color-state-positive-secondary,
        'tertiary': $color-state-positive-tertiary,
      ),
      'negative': (
        'primary': $color-state-negative-primary,
        'secondary': $color-state-negative-secondary,
        'tertiary': $color-state-negative-tertiary,
      ),
      'warning': (
        'primary': $color-state-warning-primary,
        'secondary': $color-state-warning-secondary,
        'tertiary': $color-state-warning-tertiary,
      ),
      'neutral': (
        'primary': $color-state-neutral-primary,
        'secondary': $color-state-neutral-secondary,
        'tertiary': $color-state-neutral-tertiary,
      ),
    ),
    'theme': (
      'blue': (
        'background': (
          'primary': $color-theme-blue-background-primary,
          'secondary': $color-theme-blue-background-secondary,
          'tertiary': $color-theme-blue-background-tertiary,
        ),
        'text': (
          'primary': $color-theme-blue-text-primary,
          'secondary': $color-theme-blue-text-secondary,
          'tertiary': $color-theme-blue-text-tertiary,
          'disabled': $color-theme-blue-text-disabled,
          'link': $color-theme-blue-text-link,
          'colored': $color-theme-blue-text-colored,
        ),
        'icon': (
          'primary': $color-theme-blue-icon-primary,
          'secondary': $color-theme-blue-icon-secondary,
        ),
      ),
      'yellow': (
        'background': (
          'primary': $color-theme-yellow-background-primary,
          'secondary': $color-theme-yellow-background-secondary,
          'tertiary': $color-theme-yellow-background-tertiary,
        ),
        'text': (
          'primary': $color-theme-yellow-text-primary,
          'secondary': $color-theme-yellow-text-secondary,
          'tertiary': $color-theme-yellow-text-tertiary,
          'disabled': $color-theme-yellow-text-disabled,
          'link': $color-theme-yellow-text-link,
          'colored': $color-theme-yellow-text-colored,
        ),
        'icon': (
          'primary': $color-theme-yellow-icon-primary,
          'secondary': $color-theme-yellow-icon-secondary,
        ),
      ),
      'orange': (
        'background': (
          'primary': $color-theme-orange-background-primary,
          'secondary': $color-theme-orange-background-secondary,
          'tertiary': $color-theme-orange-background-tertiary,
        ),
        'text': (
          'primary': $color-theme-orange-text-primary,
          'secondary': $color-theme-orange-text-secondary,
          'tertiary': $color-theme-orange-text-tertiary,
          'disabled': $color-theme-orange-text-disabled,
          'link': $color-theme-orange-text-link,
          'colored': $color-theme-orange-text-colored,
        ),
        'icon': (
          'primary': $color-theme-orange-icon-primary,
          'secondary': $color-theme-orange-icon-secondary,
        ),
      ),
      'salad': (
        'background': (
          'primary': $color-theme-salad-background-primary,
          'secondary': $color-theme-salad-background-secondary,
          'tertiary': $color-theme-salad-background-tertiary,
        ),
        'text': (
          'primary': $color-theme-salad-text-primary,
          'secondary': $color-theme-salad-text-secondary,
          'tertiary': $color-theme-salad-text-tertiary,
          'disabled': $color-theme-salad-text-disabled,
          'link': $color-theme-salad-text-link,
          'colored': $color-theme-salad-text-colored,
        ),
        'icon': (
          'primary': $color-theme-salad-icon-primary,
          'secondary': $color-theme-salad-icon-secondary,
        ),
      ),
      'mint': (
        'background': (
          'primary': $color-theme-mint-background-primary,
          'secondary': $color-theme-mint-background-secondary,
          'tertiary': $color-theme-mint-background-tertiary,
        ),
        'text': (
          'primary': $color-theme-mint-text-primary,
          'secondary': $color-theme-mint-text-secondary,
          'tertiary': $color-theme-mint-text-tertiary,
          'disabled': $color-theme-mint-text-disabled,
          'link': $color-theme-mint-text-link,
          'colored': $color-theme-mint-text-colored,
        ),
        'icon': (
          'primary': $color-theme-mint-icon-primary,
          'secondary': $color-theme-mint-icon-secondary,
        ),
      ),
      'red': (
        'background': (
          'primary': $color-theme-red-background-primary,
          'secondary': $color-theme-red-background-secondary,
          'tertiary': $color-theme-red-background-tertiary,
        ),
        'text': (
          'primary': $color-theme-red-text-primary,
          'secondary': $color-theme-red-text-secondary,
          'tertiary': $color-theme-red-text-tertiary,
          'disabled': $color-theme-red-text-disabled,
          'link': $color-theme-red-text-link,
          'colored': $color-theme-red-text-colored,
        ),
        'icon': (
          'primary': $color-theme-red-icon-primary,
          'secondary': $color-theme-red-icon-secondary,
        ),
      ),
      'pink': (
        'background': (
          'primary': $color-theme-pink-background-primary,
          'secondary': $color-theme-pink-background-secondary,
          'tertiary': $color-theme-pink-background-tertiary,
        ),
        'text': (
          'primary': $color-theme-pink-text-primary,
          'secondary': $color-theme-pink-text-secondary,
          'tertiary': $color-theme-pink-text-tertiary,
          'disabled': $color-theme-pink-text-disabled,
          'link': $color-theme-pink-text-link,
          'colored': $color-theme-pink-text-colored,
        ),
        'icon': (
          'primary': $color-theme-pink-icon-primary,
          'secondary': $color-theme-pink-icon-secondary,
        ),
      ),
      'violet': (
        'background': (
          'primary': $color-theme-violet-background-primary,
          'secondary': $color-theme-violet-background-secondary,
          'tertiary': $color-theme-violet-background-tertiary,
        ),
        'text': (
          'primary': $color-theme-violet-text-primary,
          'secondary': $color-theme-violet-text-secondary,
          'tertiary': $color-theme-violet-text-tertiary,
          'disabled': $color-theme-violet-text-disabled,
          'link': $color-theme-violet-text-link,
          'colored': $color-theme-violet-text-colored,
        ),
        'icon': (
          'primary': $color-theme-violet-icon-primary,
          'secondary': $color-theme-violet-icon-secondary,
        ),
      ),
    ),
  ),
);
